/**
 * arrayHandler.js
 * 陣列相關
 */
export default {
  /**
   * [array_column]
   * @param float num
   * @param int pos
   * @param boolean unique  // 是否彙整回傳唯一值
   */
  array_column (data = null, colName = '', unique = false) {
    if (data === null || !data || colName === '') {
      return [];
    }
    let output = [];
    data.forEach(function (r) {
      output.push(r[colName]);
    }, output);

    if (unique) {
      output = this.array_unique(output);
    }

    return output;
  },
  /**
   * [array_unique]
   * @param {*} data
   */
  array_unique (data = null) {
    if (data === null || !data) {
      return [];
    }
    // Array.filter => [v]:原陣列目前所迭代處理中的元素，[k]:原陣列目前所迭代處理中的元素之索引，[source]: 可選的。執行 callback 回呼函式的 this 值
    var output = data.filter(function (v, k, source) {
      return typeof v === 'undefined' ? false : source.indexOf(v) === k;
    });
    return output;
  },
  /**
   * [array_column_by_object]
   * @param object data
   * @param colName value's colname
   */
  array_column_by_object (data = null, colName = '') {
    console.log(`[arrayHandler] ...${colName}`);
    console.log(data);
    if (data === null || !data || colName === '') {
      return {};
    }
    var param = { data: data, colName: colName };
    const output = Object.keys(data).map(function (k) {
      var obj = {};
      const _val = param.data;
      const _id = param.colName;
      obj[k] = _val[k][_id].value;
      return obj;
    });
    console.log(output);
    return output;
  }
};
